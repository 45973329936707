.caroussel {
  .item {
    height: 16rem;
    min-width: 100%;
    padding: 0;

    object-fit: cover;
    aspect-ratio: 1/1;
  }
  .itemNoList {
    height: 13rem;
    padding-bottom: 2rem;
    width: 13rem;
    object-fit: cover;
    aspect-ratio: 1/1;
  }

  .itemDetail {
    height: 35rem;
    width: 100%;
    object-fit: contain;
    background-color: black;
    //aspect-ratio: 1/1;
  }
}

@media screen and (max-width: 564px) {
  .caroussel {
    .item {
      height: 8rem;
    }
    .itemNoList {
      padding-bottom: 0;
      width: 9rem;
      height: auto;
    }
    .itemDetail {
      height: 24.3rem;
      width: 100%;
      background-color: transparent;
    }
  }
}

.carousselConfig {
  .item {
    height: 8rem;
    padding: 0;
  }
  .itemNoList {
    padding-bottom: 0;
    width: 9rem;
    height: auto;
    object-fit: cover;
    aspect-ratio: 1/1;
  }
  .itemDetail {
    height: 25rem;
    // background-color: black;
    width: 100%;
  }
}
