#verticalItem {
    background-color: transparent;
    width: 120%;
   
    .card {
        border: 1px solid transparent;
        height: 11rem;
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        width: 90%;

        .caroussel {
            // width: 70%;
        }

       
        .content {
            text-align: left;
            width: 100%;
            padding-left: 5px;
            //height: 8.2rem;
            overflow-y: hidden;
            text-overflow: ellipsis;

            .name {
                font-size: xx-large;
                font-family: "Playwrite GB S", cursive;
            }

            .ingredients {
                list-style: none;

                .title {
                    font-weight: 700;
                }

                li {
                    display: inline;
                }
            }
        }
    }
}
@media screen and (max-width: 564px) {
    #verticalItem {
        width: 100%;

        .card {
            
            height: 8.2rem;
            display: flex;
            flex-direction: row;
            justify-content: space-between;
            width: 100%;

            .content {
                text-align: left;
                width: 100%;
                height: 8.2rem;
                overflow-y: hidden;
                text-overflow: ellipsis;

                .name {
                    font-size: large;
                }

                .ingredients {
                    list-style: none;

                    .title {
                        font-weight: 700;
                    }

                    li {
                        display: inline;
                    }
                }
            }
        }
    }
}
