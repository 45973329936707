.menu {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  .footer {
    margin-top: auto;
  }
  .restoBloc {
    padding: 2rem;
    margin: 0 auto;
    align-items: center;

    .restoName {
      font-family: fantasy;
      font-size: xx-large;
      font-weight: lighter;
      padding-top: 1rem;
      padding-bottom: 1rem;
    }

    img {
      width: 10%;
      height: 10%;
      padding: 3px;
      border-radius: 5rem;
      border: 0.5px solid;
      border-color: #f58216;
    }
  }

  .menuContainer {
    margin-top: 1rem;
    text-align: left;
    // font-family: system-ui;
    font-family: "Playwrite GB S", cursive;


    .titleMenu {
      // font-family: Cambria, Cochin, Georgia, Times, "Times New Roman", serif;
      font-size: xx-large;
    }

    .blocTitre {
      display: flex;
      justify-content: space-between;

      .seeMore {
        font-size: small;
        color: #f58216;
        font-weight: medium;
        text-transform: uppercase;
        cursor: pointer;
      }
    }

    .section {
      .sectionTitle {
        padding-bottom: 1rem;
        font-size: x-large;
        background-color: #f58216;
        color: white;
        padding: 5px;
        border-radius: 3px;
      }
      .category {
        margin-bottom: 1rem;

        .blocTitre {
          .categoryTitle {
            font-size: large;
            font-weight: 700;
          }
        }
      }
    }
  }
}
.loaderSection {
  position: relative;

  top: 50%;
  left: 50%;
  margin-top: 23rem;
  width: 100%;
  transform: translate(-50%, -50%);
}
@media screen and (max-width: 564px) {
  .menu {
    .footer {
      position: relative;
    }
    .restoBloc {
      padding: 2rem;
      margin: 0 auto;
      align-items: center;

      .restoName {
        font-family: fantasy;
        font-size: large;
        font-weight: lighter;
        padding-top: 1rem;
      }

      img {
        width: 30%;
        height: 30%;
        padding: 2px;
        border-radius: 3rem;
        border: 0.5px solid;
        border-color: #f58216;
      }
    }
    .menuContainer {
      .titleMenu {
        font-size: xx-large;
      }
    }
  }
}

.menuCreation {
  align-items: center;
  position: relative;
  height: 100vh;
  overflow-y: auto;
  scroll-behavior: smooth;

  //designer la scrollbar
  &::-webkit-scrollbar {
    width: 0.2rem;
    &-thumb {
      background-color: #ffffff39;
      width: 0.1rem;
      border-radius: 1rem;
    }
  }

  // font-family: Verdana, Geneva, Tahoma, sans-serif;
  // display: flex;

  .bloc,
  .bloc2 {
    display: flex;
  }
  .bloc {
    justify-content: center;
  }
  .bloc2 {
    justify-content: space-between;
    align-items: center;
  }

  .header {
    width: 70%;
    padding: 1rem;
    margin: 0 auto;
    text-align: center;
    border-bottom: 1px solid rgba(0, 0, 0, 0.185);
    // border-radius: 3rem;
    // background-color: white;

    div {
      .title {
        font-size: xx-large;
        font-weight: 100;
        margin-bottom: 3rem;
      }
      .desc {
        font-size: large;
        font-weight: 100;
        margin-bottom: 1rem;
      }
      .blocActiveMenu {
        margin-top: 2rem;
        padding: 1rem;
        margin-bottom: 1rem;
        border: 1px solid #f5821673;
        border-radius: 3rem;
      }
    }
  }
  .contenu {
    border: 3px solid rgba(0, 0, 0, 0.411);
    border-radius: 1rem;
    width: 400px;
    margin: 0 auto;
    padding-top: 1rem;
    padding-bottom: 4rem;
    padding-left: 1rem;
    padding-right: 1rem;
    margin-top: 1rem;
    margin-bottom: 1rem;

    .navBar {
      display: flex;
      align-items: center;
    }
  }
  .restoBloc {
    padding: 2rem;

    .updateLogo {
      text-align: center;
      font-size: small;
      color: #f58216;
      text-decoration: underline;
      text-transform: capitalize;
    }

    .restoName {
      font-family: fantasy;
      font-size: large;
      font-weight: lighter;
      padding-top: 1rem;
      text-align: center;
    }

    .searchBar {
      width: 100%;
    }

    .restoName,
    .logoResto {
      display: flex;
      justify-content: center;
    }

    img {
      width: 30%;
      height: 30%;
      padding: 2px;
      border-radius: 3rem;
      border: 0.5px solid;
      border-color: #f58216;
    }
  }
  .menuContainer {
    .titleMenu {
      font-size: xx-large;
    }

    .sections {
      .section,
      .sectionEdit {
        transition: all 0.4s ease-in-out;
        margin-bottom: 2rem;
        margin-top: 2rem;
        .sectionTitle {
          margin-bottom: 1rem;
          font-size: x-large;
          background-color: #f58216;
          width: 100%;
          color: white;
          padding: 5px;
          border-radius: 3px;
          cursor: pointer;
          transition: all 0.4s ease;
        }
        .sectionTitle:active {
          transform: scale(0.9);
        }
        .category {
          margin-top: 2rem;

          .blocTitre {
            .categoryTitle {
              font-size: large;
              font-weight: 700;
            }
          }
        }
      }

      .sectionEdit:active {
        transform: scale(0.9);
      }

      .sectionEdit {
        .sectionTitle {
          width: 100%;
          background-color: #f58216;
          color: white;
        }
      }
    }
  }
}

@media screen and (max-width: 564px) {
  .menuCreation {
    display: block;
    height: 86.6vh;

    .header {
      width: 100%;
      text-align: left;
      border: 0;
      border-radius: 0;
      font-family: Verdana, Geneva, Tahoma, sans-serif;

      div {
        .blocActiveMenu {
          border-radius: 1rem;
        }

        .title {
          font-size: 2rem;
          font-weight: 100;
          margin-bottom: 1rem;
          width: 100%;
        }
      }
    }

    .contenu {
      padding-left: 5px;
      padding-right: 5px;
      width: 100%;
    }
  }
}
