.buttonFluid {
  z-index: 2;
  display: block;
  width: fit-content;
  height: auto;
  outline: none;
  border: none;
  background-color: inherit;
  font-size: 24px;
  font-weight: bold;
  color: #f58216;
  position: relative;
  transition: all 0.4s ease-in-out;

  cursor: pointer;
}

.buttonFluid::before {
  content: "";
  display: block;
  width: 100%;
  height: 100%;
  z-index: 3;
  position: absolute;
  top: 0%;
  left: 0%;
  transform: scaleX(0.2) scaleY(0.5) translate(250%, 100%);
  border-top: solid 2px #333;
  border-left: solid 4px #333;
  transition: all 0.4s ease-in-out;
}

.buttonFluid:hover {
  color: whitesmoke;
  background-color: #f58216;
}
.buttonFluid:active {
  transform: scale(0.9);
}

.buttonFluid::after {
  content: "";
  display: block;
  width: 100%;
  height: 100%;
  z-index: 3;
  position: absolute;
  top: 0;
  left: 0;
  transform: translate(-50%, -50%) scaleX(0.2) scaleY(0.5);
  border-bottom: solid 2px #333;
  border-right: solid 4px #333;
  transition: all 0.4s ease-in-out;
}

.buttonFluid:hover::before {
  transform: translate(0%, 0%) scaleX(1) scaleY(1);
  border-top: solid 1px #333;
  border-left: solid 1px #333;
}

.buttonFluid:hover::after {
  transform: scaleX(1) scaleY(1) translate(0%, 0%);
  border-bottom: solid 1px #333;
  border-right: solid 1px #333;
  
}
