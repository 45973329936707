.search {
    width: 50%;
    margin: 0 auto;
    margin-top: 2rem;
}

.configSearch {
    width: 100%;
    margin: 0 auto;
    margin-top: 2rem;
}

@media screen and (max-width: 590px) {
    .search {
        width: 100%;
    }
}
