.inputFile {
    width: 100%;
    font-size: small;
    .blocBtn {
        text-wrap: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        display: flex;
        align-items: center;
        
        justify-content: center;
        #fileName {
            color: rgb(92, 88, 88);
            font-style: italic;
            text-wrap: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
            width: 150px;
        }
        // #btn {
            
        // }
    }
}