.updatePwd {
  //height: 100vh;
 position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);

  .bloc {
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding: 1rem;
    width: 22rem;
    height: 30rem;
    background-color: #fff; // Couleur de fond de la div
    border-radius: 8px; // Facultatif : pour arrondir les coins de la div
    box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.5);

    .title {
      text-transform: uppercase;
      font-weight: 300;
    }

    .field {
      margin-top: 1rem;
    }
  }
}
