body {
  background-color: white;

  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  scroll-behavior: smooth;
}
body::-webkit-scrollbar {
  width: 0.2rem;
  &-thumb {
    background-color: #ffffff39;
    width: 0.1rem;
    border-radius: 1rem;
  }
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

a {
  color: black;
  text-decoration: none;
}

/* 
img {
  z-index: -100;
} */
