.navBar {
    justify-content: space-between;
    width: 90%;
    margin: 0 auto;
   

    .logo {
        width: 11rem;
        height: 5rem;
        background-color: transparent;
       
    }

    
}

@media screen and (max-width: 564px) {
    .navBar {
        width: 100%;
       
        .logo {
            width: 8rem;
            height: 4rem;
            background-color: transparent;
        }
    }
    
}