.item {
    display: flex;
    justify-content: space-between;
    z-index: 30;
    // font-family: "Playwrite GB S", cursive;

    .price {
        display: flex;
        div {
            padding-right: 1rem;
        }
    }
}