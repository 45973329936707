.formular {
  margin-top: 5rem;

  .form {
    margin-top: 1rem;
    width: 40%;

    .item {
      margin-top: 1rem;
    }

    .contentPwd {
      font-size: x-small;
      .indicator {
        padding: 0;
      }
    }
  }

  .errorText {
    color: red;
  }

  .validateBtn {
    margin-top: 1rem;
    color: whitesmoke;
    background-color: #f58216;
    padding: 1rem;
    border-radius: 3px;
    cursor: pointer;
    text-transform: uppercase;
    transition: all ease 0.4s;
  }

  .validateBtn:hover {
    background-color: #d36e10;
  }

  .validateBtn:active {
    transform: scale(0.9);
  }
}

.formular2 {
  width: 50%;

  .title {
    color: #f58216;
  }
  .line {
    width: 100%;
    justify-content: space-between;
  }
  .section {
    border: 0.5px solid rgba(0, 0, 0, 0.301);

    border-radius: 2px;
    margin-top: 1rem;
    padding: 1rem;

    .data {
      text-transform: capitalize;
    }
  }
}

@media screen and (max-width: 903px) {
  .formular {
    .form {
      width: 100%;

      .contentPwd {
        font-size: small;
        .indicator {
          padding: 0;
        }
      }

      .item {
        margin-top: 1rem;
      }
    }
  }

  .formular2 {
    width: 100%;
  }
}
