.tarif {
  width: 94%;
  margin: 0 auto;
  padding-top: 2rem;

  display: flex;
  flex-direction: column;
  justify-content: center;

  font-family: system-ui, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto,
    Oxygen, Ubuntu, Cantarell, "Open Sans", "Helvetica Neue", sans-serif;

  .countryInfo {
    width: 34%;
    padding-left: 2.5rem;
    margin-bottom: 1rem;
  }
  .header {
    padding-bottom: 4rem;
    .title {
      font-size: 3rem;
      font-weight: 300;
    }
    .subTitle {
      font-size: x-large;
      font-weight: 300;
    }
  }
  .items {
    display: flex;
    justify-content: center;
    cursor: cell;

    .item {
      display: flex;
      justify-content: space-between;

      flex-direction: column;
      padding: 1rem;
      border: 1px solid black;
      margin: 7px;
      width: 30%;
      border-radius: 3px;

      .top {
        width: 80%;
        margin: 0 auto;

        .title {
          font-size: 2rem;
          font-weight: 500;
        }
        .firstPrice {
          font-size: 4rem;
          font-weight: 700;
        }
        .price {
          font-size: x-large;
          font-weight: 500;
          display: flex;
          justify-content: center;
          min-height: 4.5rem;
          width: 80%;

          margin: 0 auto;
        }
        .freeBtn {
          color: white;
          background-color: #f58216;
          font-weight: 500;
          padding: 1rem;
          border-radius: 3px;
          font-size: 1rem;

          margin-top: 1rem;
          margin-bottom: 1rem;
        }
        .description {
          font-size: larger;
          font-weight: 300;
        }
      }
      .bottom {
        margin: 0 auto;
        width: 80%;
        padding-bottom: 1rem;

        .checks {
          margin-bottom: 1rem;
          .check {
            display: flex;
            justify-content: space-between;
            padding-bottom: 1rem;
            .icon {
              img {
                width: 1rem;
                height: 1rem;
              }
            }
            .text {
              font-size: larger;
              font-weight: 350;
              width: 90%;
              text-align: left;
            }
          }
        }
      }

      .select {
        width: 100%;

        .btn {
          justify-content: center;
          color: white;
          background-color: #f58216;
          text-transform: uppercase;
          padding: 1rem;
          border-radius: 3px;
          font-size: 1rem;
          width: 100%;

          font-weight: 500;
          transition: all 0.4s ease;
        }
        .text {
          font-size: larger;
          font-weight: 400;
          transition: all 0.4s ease;
        }
        .text:hover {
          text-decoration: underline;
        }
        .btn:hover {
          background-color: #d46d0b;
        }
        .btn:active {
          transform: scale(0.9);
        }
      }
    }
  }

  @media screen and (max-width: 790px) {
    .countryInfo {
      width: 100%;
      padding: 1rem;
    }
    .header {
      .title {
        font-size: 2rem;
        width: 100%;
        padding-bottom: 2rem;
      }
      .subTitle {
        font-size: larger;
        font-weight: 300;
        padding-left: 1rem;
        padding-right: 1rem;
      }
    }
    .items {
      .item {
        width: 100%;
        margin: 3px;

        .top {
          width: 100%;

          .title {
            font-size: 2rem;
            font-weight: 360;
          }
          .firstPrice {
            font-size: 4rem;
            font-weight: 300;
          }
          .price {
            font-size: larger;
            font-weight: 400;
            width: 66%;
          }

          .description {
            font-size: larger;
            font-weight: 300;
          }
        }

        .bottom {
          width: 100%;

          .select {
            //border: 1px solid black;
            margin-top: auto;
            margin: 0 auto;
            width: 100%;

            .text {
              display: none;
            }
          }
        }
      }
    }
  }
}
