.reset {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 87vh;
  
  .content {
    background-color: whitesmoke;

    width: 30rem;
    height: 30rem;
    background-color: #fff; // Couleur de fond de la div
    border-radius: 8px; // Facultatif : pour arrondir les coins de la div
    box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.5);
   
    .sendEmail,
    .enterCode {
      padding: 1rem;
      .image {
        padding: 1rem;
        img {
          width: 10%;
        }
      }

      .codeResend {
        color: rgba(7, 7, 155, 0.644);
        text-decoration: underline;
        margin: 1rem;
        cursor: pointer;
        transition: all .4s ease;
      }
      .codeResend:active {
       transform: scale(0.97);
      }

      .compteur{
        margin-top: 1rem;
        margin-bottom: 1rem;
        font-size: small;
        font-style: italic;
        color: rgba(0, 0, 0, 0.534);
      }

      .inputs {
        display: flex;
        justify-content: center;
        .digit{
            width: 10%;
            margin: 1rem;
        }
      }

      .title {
        font-size: xx-large;
        font-weight: 100;
        margin-bottom: 1rem;
      }

      .instruction {
        color: rgba(0, 0, 0, 0.733);
      }
      .emailField {
        width: 100%;
        margin-top: 3rem;
        margin-bottom: 1rem;
      }
    }
  }
}

@media screen and (max-width: 700px) {
  .reset {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 87vh;
   
    .content {
     
      width: 22rem;
      height: 30rem;
      

      border-radius: 8px; // Facultatif : pour arrondir les coins de la div
      box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.5);
      
      .sendEmail,
      .enterCode {
        padding: 1rem;
        .image {
          padding: 1rem;
          img {
            width: 10%;
          }
        }

        .title {
          font-size: xx-large;
          font-weight: 100;
          margin-bottom: 1rem;
        }

        .instruction {
          color: rgba(0, 0, 0, 0.733);
        }
        .emailField {
          width: 100%;
          margin-top: 3rem;
          margin-bottom: 1rem;
        }
      }
    }
  }
}
