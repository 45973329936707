.qrcode {
  display: flex;
  align-items: center;
  z-index: 0;
  min-height: 100vh;
  scroll-behavior: smooth;

  padding: 3rem;
  .contenu {
    display: flex;
    margin: 0 auto;
    width: 90%;

    .code {
      margin-right: 1rem;
      padding: 1rem;
      border-color: black;
      box-shadow: 2px 2px 7px rgba(0, 0, 0, 0.3);
      border-radius: 1rem;
      width: 40%;

      .logo {
        margin: 0 auto;
        text-align: center;
        img {
          width: auto;
          height: 3rem;
        }
      }

      .title {
        text-align: center;
        align-items: center;
        padding: 1rem;
        text-transform: uppercase;
        font-weight: 100;
        .restoName {
          font-size: small;
          text-transform: lowercase;
        }
        div {
          margin-right: 1rem;
        }
      }
    }

    .blocContent {
      font-weight: 300;
      .title {
        font-size: x-large;

        padding-bottom: 1rem;
      }
      .desc {
        color: rgba(0, 0, 0, 0.952);
      }
      .indic {
        background-color: rgb(162, 162, 219);
      }
      .colors {
        display: flex;
        // overflow-x: auto;
        // width: 90%;
        // scroll-behavior: smooth;
        // margin: 0 auto;
        cursor: pointer;
        padding: 1rem;
        padding-top: 2rem;
        // padding-left: 8rem;

        .color {
          padding-right: 1rem;
          transition: all 0.5s ease-in-out;
        }
        .color:active {
          transform: scale(0.9);
        }
      }
    }
  }
}

@media screen and (max-width: 1400px) {
  .qrcode {

    display: flex;
    padding: 1rem;
    padding-top: 1rem;
    padding-bottom: 1rem;
    height: 86.6vh;

    .contenu {
      display: block;
      width: 100%;
      text-align: center;
      padding-top: 4rem;
      padding-bottom: 4rem;

      max-height: 100vh;
      overflow-y: auto;

      &::-webkit-scrollbar {
        width: 0.2rem;
        &-thumb {
          background-color: transparent;
          width: 0.1rem;
          border-radius: 2rem;
        }
      }

      .blocContent {
        font-weight: 300;
        .title {
          font-size: xx-large;
          padding-bottom: 1rem;
        }

        .button {
          margin: 0 auto;
          margin-top: 2rem;
          margin-bottom: 2rem;
        }

        .desc {
          font-size: larger;
          font-weight: 100;
        }

        .code {
          margin: 0 auto;
          width: 80%;
          box-shadow: 0 0 0 rgba(0, 0, 0, 0.3);

          .inner {
            width: 100%;

            margin-right: 0;
            padding: 1rem;
            border-color: black;
            box-shadow: 2px 2px 7px rgba(0, 0, 0, 0.3);
            border-radius: 1rem;
            margin-top: 2rem;

            .logo {
              img {
                width: auto;
                height: 3rem;
              }
            }
          }

          .title {
            font-size: large;

            div {
              margin-right: 5px;
            }
          }
        }

        .colors {
          display: flex;
          cursor: pointer;
          padding-top: 2rem;
          align-items: center;
          justify-content: center;
          .color {
            padding-right: 1rem;
            transition: all 0.5s ease-in-out;
          }
          .color:active {
            transform: scale(0.9);
          }
        }
      }
    }
  }
}
