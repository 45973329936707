.list {
    padding: 1rem;
    .section {
        .sectionName {
            background-color: lightgray;
            border-radius: 3px;
            padding-left: 1rem;
            font-family: "Playwrite GB S", cursive;
        }
    }
    .bgImage {
        position: absolute;
        z-index: -1;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background: url("../../public/assets/images/oresto.png") no-repeat center;
        opacity: 0.1;
    }
    .footer {
        margin-top: 1rem;
        position: relative;
        border-radius: 4px;
        padding-bottom: 1px;
       
        .addition {
            display: flex;
            justify-content: space-between;
            padding: 1rem;
           

            .title {
                font-weight: 600;
                text-transform: uppercase;
                // font-family: "Playwrite GB S", cursive;
            }
            .total {
                font-size: large;
                font-weight: 600;
            }
        }

        #appetit {
            margin: 2rem;
            text-align: center;
            font-size: 1rem;
            color: darkgray;
            font-family: "Playwrite GB S", cursive;
        }

        img {
            filter: opacity(0.1);
            position: absolute;
            bottom: 1rem;
        }
    }
}
