
.restaurants {
  background-color: whitesmoke;
  padding: 1rem;

  .items {
    display: flex;
    justify-content: center;
    text-align: center;

    .partenaire {
      width: 100%;
      .logo {
        display: flex;
        justify-content: center;
      }
    }
  }

  .title {
    font-size: 3rem;
    font-weight: 300;
  }
  .subTitle {
    font-weight: 100;
    font-size: 1.4rem;
    padding: 1rem;
  }
}

.menuPage {
  font-family: system-ui, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto,
    Oxygen, Ubuntu, Cantarell, "Open Sans", "Helvetica Neue", sans-serif;
  width: 80%;
  margin: 0 auto;

  section {
    margin-bottom: 2rem;
  }


  .header {
    .title2,
    .subTitle {
      text-align: left;
      margin-bottom: 2rem;
    }
    .title2 {
      font-size: 3rem;
      font-weight: 300;
      margin-top: 2rem;
    }
    .subTitle {
      font-weight: 300;
      font-size: x-large;

      button {
        padding: 0;
        margin: 0;
        border: none;
        background: none;
        padding-top: 1rem;
        transition: 0.1s ease-in;
      }

      button:active {
        transform: scale(0.9);
      }

      button {
        --primary-color: #111;
        --hovered-color: #f58216;
        position: relative;
        display: flex;
        font-weight: 400;
        font-size: 20px;
        gap: 0.5rem;
        align-items: center;
      }

      button p {
        margin: 0;
        position: relative;
        font-size: 20px;
        color: var(--primary-color);
      }

      button::after {
        position: absolute;
        content: "";
        width: 0;
        left: 0;
        bottom: -7px;
        background: var(--hovered-color);
        height: 2px;
        transition: 0.1s ease-out;
      }

      button p::before {
        position: absolute;
        /*   box-sizing: border-box; */

        // content: "S'inscrire";
        width: 0%;
        inset: 0;
        color: var(--hovered-color);
        overflow: hidden;
        transition: 0.1s ease-out;
      }

      button:hover::after {
        width: 100%;
      }

      button:hover p::before {
        width: 100%;
        color: var(--hovered-color);
      }

      button:hover svg {
        transform: translateX(4px);
        color: var(--hovered-color);
      }

      button svg {
        color: var(--primary-color);
        transition: 0.1s;
        position: relative;
        width: 15px;
        transition-delay: 0.1s;
      }
    }
    .blocInscription {
      display: flex;
      //position: relative;
      //background-color: red;
      justify-content: space-between;
      .image {
        img {
          width: 43rem;
          height: auto;
        }
      }
      .formular {
        background-color: white;
        width: 100%;
        margin-top: 1rem;
        padding-left: 2rem;
        border-radius: 3px;
        margin: 0 auto;

        .personnal,
        .private {
          width: 100%;

          // div {
          //   width: 100%;
          // }
        }

        #filled-basic {
          font-weight: 200;
          padding-bottom: 1rem;
          //margin-bottom: 1rem;
          background-color: white;
          width: 100%;
          //padding-left: 1rem;
          border-radius: 3px;
          color: rgba(27, 26, 26, 0.904);
        }

        .button {
          align-self: left;
          margin-left: 0;
          margin-top: 1rem;
          width: 60%;
        }

        .button:active {
          transform: scale(0.9);
        }
      }
    }
  }

  .avantages {
    .items {
      display: flex;
      margin-top: 1rem;
      margin-bottom: 1rem;
      justify-content: center;
      align-content: center;
      margin: 0 auto;
      width: 90%;
    }
  }

  .video {
    background-color: whitesmoke;
    margin-bottom: 1rem;
    height: 12rem;
    justify-content: center;
  }

  .final {
    margin-bottom: 9rem;
    margin-top: 5rem;
    .btn {
      margin: 0 auto;
      margin-top: 2rem;
    }

    .btn:active,
    .btn2:active {
      transform: scale(0.9);
    }
    .bloc {
      display: flex;
      justify-content: center;
      text-align: center;
      position: relative;
      // border: 1px solid black;
      .subTitle {
        font-weight: 300;
        font-size: x-large;
        width: 60%;
        text-align: left;
        margin-top: 12rem;
        //position: absolute;
      }

      img {
        width: 22rem;
        //height: 19rem;
      }
    }

    .title2 {
      font-size: 3rem;
      font-weight: 400;
    }
  }

  .title {
    font-size: 4rem;
    font-weight: 300;
  }
  .title2 {
    font-size: 3rem;
    font-weight: 300;
  }

  .button,
  .btn {
    background-color: #f58216;
    color: whitesmoke;
    text-transform: uppercase;
    width: 40%;
    padding: 12px;
    margin: 1rem;
    border-radius: 3px;
    cursor: pointer;
    transition: all 0.4s ease-in;
  }

  .btn2 {
    margin: 0 auto;
    background-color: rgb(52, 51, 51);
    color: white;
    width: 40%;
    padding: 12px;
    border-radius: 3px;
    cursor: pointer;
    transition: all 0.4s ease-in;
    text-transform: uppercase;
  }

  .btn:hover {
    background-color: #f58216;
    color: white;
  }
}

@media screen and (max-width: 1183px) {
  .menuPage {
    font-family: system-ui, -apple-system, BlinkMacSystemFont, "Segoe UI",
      Roboto, Oxygen, Ubuntu, Cantarell, "Open Sans", "Helvetica Neue",
      sans-serif;
    width: 80%;
    margin: 0 auto;

    section {
      margin-bottom: 2rem;
    }

    .header {
      .title2,
      .subTitle {
        text-align: left;
        margin-bottom: 2rem;
      }
      .title2 {
        font-size: 3rem;
        font-weight: 300;

        margin-top: 2rem;
      }
      .subTitle {
        font-weight: 300;
        font-size: x-large;
      }
      .blocInscription {
        display: flex;
        position: relative;
        //background-color: red;
        justify-content: space-between;
        .image {
          img {
            width: 33rem;
            height: 33rem;
          }
        }
        .formular {
          .button {
            width: 95%;
          }
        }
      }
    }

    .avantages {
      .items {
        display: flex;
        margin-top: 1rem;
        margin-bottom: 1rem;
        //border: 1px solid black;
        justify-content: center;
        align-content: center;
        margin: 0 auto;
        width: 90%;
      }
    }

    .video {
      background-color: whitesmoke;
      margin-bottom: 1rem;
      height: 12rem;
      justify-content: center;
    }

    .final {
      margin-bottom: 9rem;
      margin-top: 5rem;
      .btn {
        margin: 0 auto;
        margin-top: 2rem;
        width: 70%;
      }

      .btn:active {
        transform: scale(0.9);
      }
      .bloc {
        display: flex;
        justify-content: center;
        text-align: center;
        position: relative;
        // border: 1px solid black;
        .subTitle {
          font-weight: 300;
          font-size: x-large;
          width: 60%;
          text-align: left;
          margin-top: 12rem;
          //position: absolute;
        }

        img {
          width: 22rem;
          //height: 19rem;
        }
      }

      .title2 {
        font-size: 3rem;
        font-weight: 400;
      }
    }

    .title {
      font-size: 4rem;
      font-weight: 300;
    }
    .title2 {
      font-size: 3rem;
      font-weight: 300;
    }

    .button,
    .btn {
      background-color: #f58216;
      color: white;
      text-transform: uppercase;
      width: 40%;
      padding: 12px;
      margin: 1rem;
      border-radius: 3px;
      cursor: pointer;
      transition: all 0.4s ease-in;
    }

    .btn:hover {
      background-color: #f58216;
      color: white;
    }
  }
}

@media screen and (max-width: 900px) {
  .menuPage {
    width: 85%;

    .header {
      .blocInscription {
        display: block;
        position: relative;

        .image {
          img {
            width: 100%;
            height: 33rem;
          }
        }
        .formular {
          width: 100%;
          position: relative;
          top: 0;
          left: 0;
          padding: 1rem;

          margin-bottom: 1rem;

          // .personnal {
          //   div {
          //     width: 98%;
          //   }
          // }
          // .private {
          //   div {
          //     width: 98%;
          //   }
          // }

          .button {
            margin-left: 0;
            width: 30%;
          }
        }
      }
    }

    .avantages {
      .items {
        margin-top: 2rem;

        width: 100%;
      }
    }

    .final {
      .btn,
      .btn2 {
        width: 80%;
      }
    }
  }
}

//---------------------------------------------------------------------------------

@media screen and (max-width: 682px) {
  .menuPage {
    width: 100%;
    margin: 0 auto;

    section {
      margin-bottom: 2rem;
    }

    .header {
      .title2,
      .subTitle {
        text-align: left;
        margin-bottom: 1rem;
        padding: 1rem;
        width: 100%;
      }
      .title2 {
        font-size: 2rem;
        margin-top: 2rem;
      }
      .subTitle {
        font-weight: 300;
        font-size: large;
      }
      .blocInscription {
        display: block;
        position: relative;
        justify-content: center;
        .image {
          img {
            width: 100%;
            height: 100%;
          }
        }
        .formular {
          width: 100%;
          position: relative;
          top: 0;
          left: 0;
          padding: 1rem;
          border-radius: 3px;
          margin-bottom: 2rem;
          padding-top: 3rem;
          padding-bottom: 3rem;

          .personnal {
            display: block;
            width: 100%;
            margin-bottom: 0;
            justify-content: center;
            div {
              // width: 100%;
              padding-bottom: 3px;
            }
          }
          .private {
            display: block;
            width: 100%;

            div {
              width: 100%;
              padding-bottom: 3px;
            }
          }

          .button {
            margin: 0 auto;
            padding: 1rem;
            font-size: large;
            width: 100%;
          }
        }
      }
    }

    .avantages {
      .items {
        display: flex;
        margin-top: 1rem;
        margin-bottom: 1rem;
        width: 98%;
      }
    }

    .final {
      margin-bottom: 9rem;
      margin-top: 4rem;
      padding: 1rem;
      .btn,
      .btn2 {
        margin: 0 auto;
        margin-top: 2rem;
        width: 100%;
      }

      .bloc {
        display: block;
        justify-content: center;
        text-align: center;

        .subTitle {
          font-size: x-large;
          width: 100%;
          text-align: center;
          margin-top: 4rem;
          padding: 0;
        }

        img {
          width: 20rem;
        }
      }

      .title2 {
        font-size: 2rem;
      }
    }

    .title {
      font-size: 3rem;
      text-align: left;
      justify-content: center;
      padding: 1rem;
    }
    .title2 {
      font-size: 3rem;
    }

    .button,
    .btn,
    .btn2 {
      width: 100%;
    }
  }
}
