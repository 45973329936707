.avantage {
    width: 100%;
    padding: 1rem;
    text-align: center;

    // border: 1px solid black;

    .label {
        font-size: xx-large;
        font-weight: 300;
        width: 100%;
        padding-bottom: 1rem;
    }
    .text {
        font-weight: 300;
        font-size: large;
        align-self: flex-end;
        // border: 1px solid orange;
        height: 100%;
        justify-content: center;
    }

    .icon {
        img {
            width: 12rem;
            height: 12rem;
        }
    }
}

@media screen and (max-width: 1026px){
    .avantage {
        width: 100%;
        padding: 1rem;
       
        //border: 1px solid black;
    
        .label {
            font-size: xx-large;
            font-weight: 300;
            width: 100%;
            padding-bottom: 1rem;
        }
        .text {
            font-weight: 300;
            font-size: large;
            align-self: flex-end;
            // border: 1px solid orange;
           
            justify-content: center;
        }
    
        .icon {
            img {
                width: 10rem;
                height: 10rem;
            }
        }
    }
}

@media screen and (max-width: 900px){
    .avantage {
        padding: 0;
    }

     
    
}

@media screen and (max-width: 564px) {
   
    .avantage {
        .icon {
            img {
                width: 10rem;
                height: 10rem;
            }
        }

     
    }
}
