#horizontalList {
  width: 100%;
  background-color: white;

  // overflow-x: hidden;
  border-radius: 1rem;
  overflow-x: auto;
  overflow-y: hidden;
  display: flex;

 
  &::-webkit-scrollbar {
    width: 0.2rem;
    height: 0.1rem;
    &-thumb {
      background-color: orange;
      width: 0.1rem;
      border-radius: 2rem;
    }
  }


  #item {
    height: 30rem;
    margin: 1rem;
    width: 20%;

    justify-content: center;

    &:last-child {
      margin-right: 0;
    }
  }
}

@media screen and (max-width: 564px) {
  #horizontalList {
    #item {
      min-height: 17rem;
      max-height: 19rem;
      margin-right: 2rem;

      width: 29%;
      min-width: 29%;
    }
  }
}

#listConfig {
  width: 100%;
  background-color: white;
  overflow-y: auto;
  // margin-bottom: 3rem;
  overflow-x: auto;
  padding-bottom: 1rem;
  padding-top: 1rem;
  display: flex;
  scroll-behavior: smooth;

  
  &::-webkit-scrollbar {
    width: 0.2rem;
    height: 0.1rem;
    &-thumb {
      background-color: orange;
      width: 0.1rem;
      border-radius: 2rem;
    }
  }


  #item,
  #itemAdd {
    min-height: 17rem;
    max-height: 19rem;
    margin-right: 3rem;

    width: 100px;
    min-width: 100px;

    justify-content: center;

    &:last-child {
      margin-right: 0;
    }
  }
  #itemAdd {
    width: 150px;
    min-width: 150px;
    cursor: pointer;
    border-top: 1px solid rgba(0, 0, 0, 0.185);
    display: flex;
    align-items: center;
    color: rgb(75, 72, 72);
    background-color: rgba(0, 0, 0, 0.062);
    transition: all 0.5s ease-in-out;
  }

  #itemAdd:active {
    transform: scale(0.9);
  }
  // #itemAdd:hover {
  //   background-color: rgba(0, 0, 0, 0.116);
  // }
}
