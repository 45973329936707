.blocCar {
  position: relative;

  .activeProduct {
    position: absolute;
    top: 50%;
    left: 50%;
    width: 100%;
    transform: translate(-50%, -50%);
    z-index: 100;
    display: flex;
    justify-content: center;
  }
}

#horizontalItem {
  margin-left: 1rem;
  //background-color: red;
  border: 1px solid transparent;
  //modifié
  width: 110%;
  height: 30rem;

  .productName {
    color: black;
    font-family: "Playwrite GB S", cursive;
    white-space: normal;
  }
  .ingredients {
    font-size: x-small;
    //background-color: red;
    width: 97%;

    .listIngredient {
      // &::-webkit-scrollbar {
      //   width: 0.2rem;
      //   height: 0.1rem;
      //   &-thumb {
      //     background-color: orange;
      //     width: 0.1rem;
      //     border-radius: 2rem;
      //   }
      // }
      list-style: none;
      height: 8.5rem;
      // background-color: red;
      // overflow-y: auto;
      // text-overflow: ellipsis;

      li {
        display: inline;
      }

      .title {
        font-weight: 700;
      }
    }
  }

  .card {
    width: 100%;
  }
  .imageProduct {
    width: 100%;
    height: 150px;
    border: 1px solid black;
  }
}

#horizontalItem:hover {
  background-color: transparent;
  color: transparent;
}

@media screen and (max-width: 564px) {
  #horizontalItem {
    width: 143%;
    min-width: 143%;
    max-height: 19rem;
    padding-bottom: 1rem;

    .imageProduct {
      width: 100%;
      height: 120px;
    }

    .content {
      padding: 0;
      padding-top: 1rem;
      padding-bottom: 1rem;

      overflow-y: auto;
      &::-webkit-scrollbar {
        width: 0.05rem;
        height: 0.1rem;
        &-thumb {
          background-color: orange;
          width: 0.1rem;
          border-radius: 2rem;
        }
      }

      .more {
        color: #f58216;
        text-decoration: underline;
        font-size: x-small;
      }

      .productName {
        font-size: small;
        font-weight: 600;
        width: 100%;
        // background-color: red;
      }
      .price {
        font-size: small;
      }

      .ingredients {
        font-size: x-small;
        //background-color: red;
        width: 97%;

        .listIngredient {
          // &::-webkit-scrollbar {
          //   width: 0.2rem;
          //   height: 0.1rem;
          //   &-thumb {
          //     background-color: orange;
          //     width: 0.1rem;
          //     border-radius: 2rem;
          //   }
          // }
          list-style: none;
          height: 4.5rem;
          // overflow-y: auto;
          // text-overflow: ellipsis;

          li {
            display: inline;
          }

          .title {
            font-weight: 600;
          }
        }
      }
    }
  }
}

.inactiveProduct {
  filter: grayscale(90%);
}

#productConfig {
  width: 143%;
  min-width: 143%;
  max-height: 19rem;
  border: 1px solid transparent;

  overflow-y: auto;
  &::-webkit-scrollbar {
    width: 0.01rem;
    height: 0.06rem;
    &-thumb {
      background-color: orange;
      width: 0.01rem;
      border-radius: 2rem;
    }
  }

  .content {
    padding: 0;
    padding-top: 1rem;
    font-family: "Playwrite GB S", cursive;

    .more {
      color: #f58216;
      text-decoration: underline;
      font-size: x-small;
    }

    .productName {
      font-size: small;
      font-weight: 600;
      width: 100%;
      // word-wrap: break-word; /* ou overflow-wrap: break-word; */
      white-space: normal;
      // overflow: hidden;
    }

    .price {
      font-size: small;
    }

    .ingredients {
      font-size: x-small;
      width: 100%;

      .listIngredient {
        // &::-webkit-scrollbar {
        //   width: 0.05rem;
        //   height: 0.1rem;
        //   &-thumb {
        //     background-color: rgba(211, 211, 211, 0.5);
        //     width: 0.1rem;
        //     border-radius: 2rem;
        //   }
        // }
        list-style: none;
        text-align: left;
        height: 4.5rem;
        // overflow-y: auto;
        // text-overflow: ellipsis;

        li {
          display: inline;
        }

        .title {
          font-weight: 600;
          font-size: small;
          width: 100%;
          padding: 0;
        }
      }
    }
  }
}

@media screen and (max-width: 564px) {
  #productConfig {
    width: 143%;
    min-width: 143%;
    border: 1px solid transparent;

    .imageProduct {
      width: 100%;
      height: 120px;
    }

    .content {
      padding: 0;
      padding-top: 1rem;

      .more {
        color: #f58216;
        text-decoration: underline;
        font-size: x-small;
      }

      .productName {
        font-size: small;
        font-weight: 600;
        width: 100%;
        //background-color: red;
      }
      .price {
        font-size: small;
      }

      .ingredients {
        font-size: x-small;
        width: 100%;
        // overflow-y: hidden;
        // text-overflow: ellipsis;

        .listIngredient {
          list-style: none;
          text-align: left;

          li {
            display: inline;
          }

          .title {
            font-weight: 600;
            font-size: small;
            width: 100%;

            padding: 0;
          }
        }
      }
    }
  }
}
