.addSection,
.sectionUpdate,
.addProduct,
.labelMenuUpdate,
.categUpdate {
  padding: 1rem;
  .content {
    .header {
      display: flex;
      justify-content: space-between;
      font-size: larger;
      margin-bottom: 2rem;
      text-transform: uppercase;
      color: rgb(43, 41, 41);

      .closeBtn {
        cursor: pointer;
      }
    }
    .desc {
      font-size: small;
      color: rgb(100, 99, 99);
      margin-bottom: 2rem;
    }
  }
}

.sectionUpdate {
  .blocCategory {
    margin-top: 2rem;
    .title {
      margin-bottom: 2rem;
      font-size: large;
      text-transform: capitalize;
      color: rgb(59, 57, 57);
    }
  }
}

.addProduct {
  margin-bottom: 2rem;
  .content {
    .switch {
      padding: 1rem;
      background-color: whitesmoke;
      border-radius: 1rem;
      margin: 0 auto;
      margin-bottom: 1rem;
      width: 100%;
     font-weight: 800;
      justify-content: center;
    }
    .blocImage {
      .caroussel {
        display: flex;
        justify-content: center;
        text-align: center;
        margin: 0 auto;
        //font-size: small;
        color: rgb(88, 86, 86);
        align-items: center;
        width: 22rem;
        height: 22rem;
        border: 1px solid rgba(128, 127, 127, 0.575);
        border-radius: 2px;

        img {
          width: 22rem;
          height: 22rem;
          object-fit: cover;
          aspect-ratio: 1/1;
        }
      }

      .listImage {
        width: 100%;
        text-align: left;
        margin: 0;
      }
    }

    .blocDetails {
      margin-top: 2rem;
      .title {
        font-size: larger;
        font-weight: 500;
      }
      .name,
      .price {
        margin-top: 1rem;
      }
      .subTitle,
      .title {
        text-transform: uppercase;
        margin-bottom: 1rem;
        margin-top: 1rem;
        color: rgb(71, 70, 70);
      }
      .subTitle {
        font-weight: 400;
      }

      .ingredients {
        margin-top: 2rem;
        border-top: 1px solid orangered;

        .listIngredient {
          text-transform: capitalize;
        }
      }
    }
  }
}
