.appBar {
  width: 100%;
  display: flex;
  justify-content: space-between;

  .logo {
    width: 8rem;
    height: 5rem;
    background-color: transparent;
    cursor: pointer;
  }

  .connect {
    text-transform: uppercase;
    font-weight: 600;
    transition: all 0.6s ease;
    cursor: pointer;
    color: #f58216;
  }

  .connect:hover {
    color: #522a07fd;
  }

  .connexionBtn {
    transition: all 0.4s ease;
    width: 100%;
    min-width: 90px;
    cursor: pointer;
    color: white;
    padding: 9px;
    border-radius: 3px;
    background-color: #f58216;
  }
  .lang {
    width: 50%;
    color: rgba(0, 0, 0, 0.548);
  }

  .connexionBtn:active,
  .connect:active {
    transform: scale(0.9);
  }
}

// @media screen and (max-width: 1026px){

// }

@media screen and (max-width: 900px) {
  .appBar {
    justify-content: space-between;

    .blocLogo {
      width: 100%;
     
      .logo {
        width: 8rem;
        height: 5rem;
        background-color: transparent;
      }
    }
    .connexionBtn {
      transition: all 0.4s ease;
      width: 100%;
      cursor: pointer;
    }
  }
}

@media screen and (max-width: 564px) {
  .appBar {
    .lang {
      width: 30%;
    }
    .connexionBtn {
      width: 100%;
      margin-left: 16px;
    }
  }
}
