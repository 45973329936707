.subscribe {
  position: relative;
  margin: 0 auto;
  min-height: 160px;
  width: 50%;
  padding: 20px;
  background-color: #fff;
  border-radius: 4px;
  color: #333;
  box-shadow: 0px 0px 50px 1px rgba(0, 0, 0, 0.4);
  margin-bottom: 4rem;
}

@media screen and (max-width: 890px) {
  .subscribe {
    // height: 160px;
    width: 95%;
  }
}

.subscribe:after {
  position: absolute;
  content: "";
  right: -10px;
  bottom: 18px;
  width: 0;
  height: 0;
  border-left: 0px solid transparent;
  border-right: 10px solid transparent;
  border-bottom: 10px solid #f58216;
}

.subscribe p {
  text-align: center;
  font-size: 20px;
  font-weight: bold;
  letter-spacing: 4px;
  line-height: 28px;
}

.subscribe input {
  // position: absolute;
  bottom: 30px;
  border: none;
  border-bottom: 1px solid #d4d4d4;
  padding: 10px;
  width: 82%;
  background: transparent;
  transition: all 0.25s ease;
}

.subscribe input:focus {
  outline: none;
  border-bottom: 1px solid #f58216;
  font-family: "Gill Sans", "Gill Sans MT", Calibri, "Trebuchet MS",
    "sans-serif";
}

.subscribe .submit-btn {
  position: absolute;
  border-radius: 30px;
  border-bottom-right-radius: 0;
  border-top-right-radius: 0;
  background-color: #f58216;
  color: #fff;
  padding: 12px 25px;
  display: inline-block;
  font-size: 12px;
  font-weight: bold;
  letter-spacing: 5px;
  right: -10px;
  bottom: -20px;
  cursor: pointer;
  transition: all 0.25s ease;
  box-shadow: -5px 6px 20px 0px rgba(26, 26, 26, 0.4);
}

.subscribe .submit-btn:hover {
  background-color: #e97406;
  box-shadow: -5px 6px 20px 0px rgba(88, 88, 88, 0.569);
}

.subscribe .submit-btn:active {
  transform: scale(0.96);
}
