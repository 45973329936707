.colorPicker {
  .container {
    position: relative;
    // .color {
    // }
    .picker {
      position: absolute;
      z-index: 100;
      width: 100%;
      top: 120%;
      right: 100%;
    }
  }
}
