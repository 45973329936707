.profil {
  height: 100vh;
  
  overflow-y: auto;
  scroll-behavior: smooth;
  font-size: small;
  &::-webkit-scrollbar {
    width: 0.2rem;
    &-thumb {
      background-color: transparent;
      width: 0.1rem;
      border-radius: 2rem;
    }
  }

  padding-bottom: 2rem;
  font-family: system-ui, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto,
    Oxygen, Ubuntu, Cantarell, "Open Sans", "Helvetica Neue", sans-serif;

  .bloc {
    height: 120vh;

    .header {
      position: relative;

      .bg {
        filter: brightness(0.7);
      }
      .info {
        position: absolute;
        background-color: white;
        border-top-left-radius: 2rem;
        border-top-right-radius: 2rem;
        bottom: 0;
        z-index: 3;
        width: 100%;
        font-size: larger;
        // text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.664);
        left: 0;
        padding: 10px; /* Ajustez la marge intérieure selon vos préférences */
        color: rgba(
          0,
          0,
          0,
          0.774
        ); /* Couleur du texte à l'intérieur de "info" */
        display: flex;
        align-items: center;
        gap: 1rem;

        #restoName {
          font-size: x-large;
          font-weight: 900;
        }
        #restoCity {
          text-transform: capitalize;
        }
      }
    }

    .body {
      .informations {
        padding: 1rem;

        .title {
          padding-bottom: 2rem;
        }

        // .personnal, .restoData {
        //     background-color: whitesmoke;
        // }
      }
    }
  }
}

.form {
  padding: 1rem;
  .head {
    display: flex;
    justify-content: space-between;
    margin-bottom: 2rem;
  }
}

@media screen and (max-width: 644px) {
  .profil {
    height: 86.6vh;
    padding-bottom: 4rem;

    .bloc {
      .header {
        .info {
          #restoName {
            font-size: x-large;
            font-weight: bold;
          }
        }
      }
    }
  }
}

.item {
  display: flex;
  font-size: larger;
  // color: rgba(0, 0, 0, 0.781);
  cursor: pointer;
  transition: all 0.4s ease;

  padding-top: 1rem;
  padding-bottom: 1rem;
  .datas {
    // display: flex;
    width: 100%;

    .label {
      text-transform: capitalize;
    }

    .data {
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding: 10px;
      border-radius: 2rem;
      text-wrap: nowrap;
      overflow-x: hidden;
      text-overflow: ellipsis;
      background-color: rgb(207, 207, 207);
    }
  }

  //   .nextBtn {
  //   }
}

.item:hover {
  background-color: whitesmoke;
}
