.menuCreationHome {
  padding: 1rem;
  display: flex;
  flex-direction: column;
  height: 89.1vh;
  overflow-y: auto;
  // margin-bottom: 5rem;

  .title {
    font-size: x-large;
  }
  .desc {
    color: rgb(83, 80, 80);
  }
  .drawer{
        
    .header {
        .title {
            background-color: red;
        }
    }
  }
  .button {
    padding: 1rem;
    background-color: #f58216;
    color: whitesmoke;
    text-transform: uppercase;
    text-align: center;
    width: 50%;
    margin-top: 1rem;
    margin-bottom: 1rem;
    cursor: pointer;
    transition: all 0.4s ease-out;
  }
  .button:active {
    transform: scale(0.9);
  }
  .section {
    border: 1px solid rgba(85, 84, 84, 0.233);
    width: 100%;
    padding: 1rem;
    margin-top: 1rem;

    a {
      color: #f58216;
      font-size: large;
      text-decoration: none;
      transition: all 0.6s ease-in-out;
    }

    a:hover {
      text-decoration: underline;
    }
  }

  .bloc {
    margin-top: 2rem;
    display: flex;
    justify-content: space-between;

    .blocCreate {
      width: 60%;
      .title {
        font-size: xx-large;
        padding-bottom: 1rem;
      }
      
    }
    .blocConfig {
      width: 40%;
      .title {
        font-size: large;
        padding-bottom: 1rem;
        font-weight: 500;
      }
      .instructions {
        .item {
          border-top: 1px solid rgba(85, 84, 84, 0.582);
          padding-bottom: 1rem;
          padding-top: 1rem;
          display: flex;
          justify-content: flex-start;

          div {
            padding-right: 1rem;
          }
        }
      }
    }
  }
}

@media screen and (max-width: 900px) {
  .menuCreationHome {
    height: 86.6vh;
    padding-bottom: 4rem;

    .button {
      width: 100%;
    }

    .bloc {
      display: block;

      .blocCreate {
        width: 100%;
      }
      .blocConfig {
        width: 100%;
      }
    }
  }
}
