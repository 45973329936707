#verticalList {
  width: 100%;
  min-height: 100vh;
  overflow-y: auto;

  .liste {
    scroll-behavior: smooth;
    &::-webkit-scrollbar {
      width: 0.2rem;
      &-thumb {
        background-color: transparent;
        width: 0.1rem;
        border-radius: 2rem;
      }
    }
  }

}
