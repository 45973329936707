.successPage {
    position: relative;
    align-items: center;
    justify-content: center;
    display: flex;
    height: 100vh;
    font-family: system-ui, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto,
    Oxygen, Ubuntu, Cantarell, "Open Sans", "Helvetica Neue", sans-serif;
    .content {
        // position: absolute;
        // left: 50%;
        // top: 50%;
        // transform: translate(-50%, -50%);
        .icon {
            margin: 0 auto;
            
           // background-color: whitesmoke;
            width: 100%;
            padding: 1rem;
            padding-top: 3rem;
            padding-bottom: 3rem;
            
        }
        .message {
            font-size: x-large;
            font-weight: 400;
            color: rgb(97, 94, 94);
            letter-spacing: 1px;
            text-transform: capitalize;
        }
        .redirect{
            padding-top: 1rem;
            align-items: center;
            .compteur {
                font-size: xx-large;
                padding-left: 1rem;
                font-weight: 700;
            }
        }
        .button {
            background-color: #F58216;
            color: whitesmoke;
            margin: 0 auto;
            margin-top: 1rem;
            width: 100%;
            padding: 1rem;
            border-radius: 2px;
            text-transform: uppercase;
            cursor: pointer;
            transition: all .5s ease-in-out;
        }
        .button:active {
            transform: scale(0.9);
        }
    }
}