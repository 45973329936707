.login {
    height: 100vh;

    #component-error-text {
        color: red;
    }

    .imageBox {
        // filter: blur(2px);
        width: 60%;
        background: url("../../public/assets/images/qrcodelogo.jpeg") center/cover no-repeat;
    }
    .form {
        position: relative;

        width: 40%;
        // padding: 2rem;
        margin: 0 auto;

        .logo {
            width: 23rem;
            height: 11rem;
            align-self: center;
            margin-top: 4rem;
        }
        .logo2 {
            display: none;
        }
        .formContent {
            margin: 0 auto;
            width: 60%;
            .field {
                .input {
                    color: #f58216;
                }
            }

            .connexion {
                background-color: #f58216;
                padding: 1rem;
                text-transform: uppercase;
                border-radius: 2px;
                font-weight: 500;
                color: whitesmoke;
            }
            .inscription,
            .forgotPassword,
            .connexion {
                cursor: pointer;
                transition: all ease-in-out 0.4s;
            }
            .connexion:active {
                transform: scale(0.9);
            }

            .connexion:hover {
                background-color: #d16c0e;
            }
            .forgotPassword:hover {
                color: black;
            }
            .inscription:hover {
                color: black;
            }
            .inscription {
                color: rgb(103, 102, 102);
                text-decoration: underline;
            }
        }
    }
}

@media screen and (max-width: 930px) {
    .login {
        .imageBox {
            display: none;
        }
        .form {
            width: 90%;

            .logo {
                width: 19rem;
                height: 9rem;
                align-self: center;
                margin-top: 4rem;
            }

            .formContent {
                width: 60%;
            }
        }
    }
}

@media screen and (max-width: 530px) {
    .login {
        .imageBox {
            display: none;
        }
        .form {
            width: 90%;

            .logo {
                display: none;
            }

            .logo2 {
                margin-top: 1rem;
                display: block;
                width: 10rem;
                height: 10rem;
                align-self: center;
            }

            .formContent {
                width: 100%;
            }
        }
    }
}
