.shop {
  padding: 3rem;
  overflow-y: auto;
  font-family: Verdana, Geneva, Tahoma, sans-serif;
  height: 100vh;

  margin: 0 auto;

  &::-webkit-scrollbar {
    width: 0.2rem;
    &-thumb {
      background-color: transparent;
      width: 0.1rem;
      border-radius: 2rem;
    }
  }

  .presentation {
    text-align: center;
    .title {
      font-size: xx-large;
      padding-bottom: 3rem;
      font-weight: 200;
      //   color: #f58216;
      //   text-shadow: #f58216 4px 2px 9px;
    }
    .subTitle {
      font-size: larger;
      font-weight: 100;
    }
  }
  hr {
    margin-top: 2rem;
    margin-bottom: 2rem;
  }
  .title {
    font-size: xx-large;
    font-weight: 100;
  }
  .articles {
    text-align: left;
    display: flex;
    margin-bottom: 4rem;

    .article {
      width: 80%;
      margin-top: 2rem;
      cursor: pointer;
      height: 20rem;

      background-color: transparent;
      position: relative;

      .button {
        // position: absolute;
        bottom: 0;
      }

      .images {
        transition: all 0.4s ease-in-out;
      }

      .images:hover {
        transform: scale(1.1);
      }
      .content {
        padding-top: 1rem;
        .name {
          font-size: small;
          font-weight: 100;
        }

        .price {
          font-size: large;
          font-weight: 700;
        }
      }
    }
  }
}

@media screen and (max-width: 890px) {
  .shop {
    height: 86.6vh;
    padding-bottom: 5rem;

    padding: 1rem;

    .presentation {
      text-align: left;

      .subTitle {
        font-size: large;
      }
    }
    .articles {
      text-align: left;
      display: flex;

      .article {
        width: 100%;
        margin-top: 2rem;
        cursor: pointer;
        min-height: 18rem;

        background-color: transparent;
        position: relative;

        .button {
          // position: absolute;
          bottom: 0;
        }

        .images {
          transition: all 0.4s ease-in-out;
        }

        .images:hover {
          transform: scale(1.1);
        }
        .content {
          padding-top: 1rem;
          .name {
            font-size: small;
            font-weight: 100;
          }

          .price {
            font-size: large;
            font-weight: 700;
          }
        }
      }
    }
  }
}
