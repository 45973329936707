.abonnement {
  font-family: "Segoe UI", Tahoma, Geneva, Verdana, sans-serif;
  height: 100vh;
  width: 100%;
  padding-bottom: 4rem;

  overflow-y: auto;
  // background-color: red;

  &::-webkit-scrollbar {
    width: 0.2rem;
    &-thumb {
      background-color: transparent;
      width: 0.1rem;
      border-radius: 2rem;
    }
  }

  .header {
    padding-top: 4rem;
    padding-bottom: 4rem;

    .contenu {
      text-align: center;
    }
  }

  .plans {
    padding: 3rem;

    // .title {
    // }
    .button {
      display: flex;
      flex-direction: column;
      width: 40%;
    }
    .plan {
      display: flex;
      justify-content: space-between;
      margin-bottom: 1rem;
      transition: all 0.4s ease-in-out;
      padding: 1rem;
      border-radius: 3px;
      align-items: center;

      .infos {
        .name {
          font-size: larger;
          font-weight: 100;
          font-family: "Gill Sans", "Gill Sans MT", Calibri, "Trebuchet MS",
            sans-serif;
        }
        width: 60%;
      }
    }

    .plan:not(:last-child) {
      border-bottom: 1px solid rgba(0, 0, 0, 0.11);
    }

    .plan:hover {
      background-color: rgba(245, 245, 245, 0.582);
    }
  }

  .subPresent {
    padding: 1rem;
    text-align: center;
    .blocHead {
      .title {
        font-size: 3rem;
        padding-bottom: 2rem;
        font-weight: 100;
      }
      .name {
        font-size: xx-large;
        font-weight: 500;
      }
      .desc {
        font-size: small;
        color: gray;
        padding-bottom: 1rem;
      }
      .status {
        padding-bottom: 2rem;
      }

      .date {
        display: flex;
        justify-content: space-evenly;
        .begin {
          text-align: left;
          .label {
            font-size: small;
          }
          // .time {
          // }
        }
        .end {
          text-align: right;
          .label {
            font-size: small;
          }
          .time {
            // font-size: large;
            color: #f58216;
            font-weight: 500;
          }
        }
      }
    }
    .btn {
      border: 1px solid rgba(0, 0, 0, 0.055);
      display: block;
      margin-bottom: 1rem;
      margin-top: 1rem;
    }
  }
}

@media screen and (max-width: 644px) {
  .abonnement {
    height: 86.6vh;
    .header {
      padding-top: 4rem;
      padding-bottom: 4rem;

      .contenu {
        text-align: center;
      }
    }

    .plans {
      padding: 1rem;

      .title {
        font-size: x-large;
      }
      .plan {
        display: flex;
        justify-content: space-between;
        margin-bottom: 1rem;
        transition: all 0.4s ease-in-out;
        padding: 0;
        border-radius: 3px;
        align-items: last baseline;

        .infos {
          .name {
            font-size: larger;
            font-weight: 100;
          }
          
        }
      }

      .plan:hover {
        background-color: rgba(245, 245, 245, 0.582);
      }
    }

    .subPresent {
      .blocHead {
        .date {
          justify-content: space-between;
          font-size: small;
        }
      }
      .btn {
        border: 1px solid rgba(0, 0, 0, 0.322);
        width: 100%;
      }
    }
  }
}
