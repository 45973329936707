.product {
  background-color: whitesmoke;
  font-family: "Playwrite GB S", cursive;
  scroll-behavior: smooth;
  margin-bottom: 1rem;

  .section:not(:last-child) {
    margin-bottom: 1rem;
    border-bottom-left-radius: 1rem;
    border-bottom-right-radius: 1rem;
  }
  .section {
    padding: 1rem;
    background-color: white;

    .name {
      font-size: x-large;
      font-weight: 400;
      font-family: "Playwrite GB S", cursive;
    }
    .price {
      font-size: large;
      font-weight: lighter;
      font-family: serif;
    }
    .ingredients {
      list-style: none;

      font-size: medium;
      font-weight: 300;
    }
    .title {
      padding-top: 1rem;
      font-size: x-large;
      font-weight: 700;
      font-family: "Playwrite GB S", cursive;
    }
    .liste {
      li {
        padding-right: 4px;
        display: inline;
      }
    }
  }

  .blocToast {
    position: relative;

    .toast {
      position: absolute;
      bottom: 25%;
      left: 50%;
      transform: translate(-50%, -50%);
      animation: fadeUp 0.9s forwards;

      background-color: #f582165e;
      text-align: center;
      width: 20%;
      border-radius: 4rem;
      margin: 0 auto;
      border: 1px solid transparent;
      .body {
        font-size: xx-large;
        font-weight: 800;
        color: whitesmoke;
      }
    }

    @keyframes fadeUp {
      from {
        bottom: 25%; /* Position initiale légèrement plus basse */
      }
      to {
        bottom: 200%; /* Position centrée */
      }
    }

    .button {
      background-color: #f58216;
      color: white;
      padding: 1rem;
      margin-bottom: 2rem;
      width: 90%;
      margin: 0 auto;
      text-align: center;
      font-weight: bold;
      font-size: medium;
      border-radius: 3px;
      transition: all 0.4s ease;
      text-transform: uppercase;
    }
    .button:active {
      transform: scale(0.9);
    }
  }
}
