.sideBar {
  color: whitesmoke;
  //padding: 1rem;
  text-align: left;
  font-family: "tiliweb regular";
  height: 100vh;
  .drawerMobile {
    background-color: rgba(0, 0, 0, 0.699);
    padding-left: 7px;
    padding-top: 1rem;

    .content {
      text-transform: capitalize;
    }
  }

  .main {
    background-color: white;

    height: 60vh;
    width: 100%;

    .contentDashbord {
      background-color: white;
      border-radius: 12px;
      color: black;
    }
  }

  .header {
    margin-bottom: 4rem;
  }
  .head {
    display: flex;
    width: 100%;
    align-items: center;
    font-size: medium;
    text-transform: capitalize;

    .title {
      padding-left: 1rem;
    }
  }

  .content {
    text-transform: capitalize;
    .title {
      font-size: x-large;
      padding: 1rem;
    }

    .liste {
      .item,
      .itemActive {
        cursor: pointer;
        padding: 1rem;
        border-radius: 3px;

        .identifiant {
          display: flex;
          width: 100%;

          .label {
            padding-left: 1rem;
            font-size: medium;
            text-transform: capitalize;
          }
        }
      }

      .item:hover {
        background-color: rgb(97, 97, 97);
        color: whitesmoke;
      }
    }
  }
  .bottom {
    position: absolute;
    bottom: 1rem;
    display: flex;
    justify-content: space-between;
  }
}

@media screen and (max-width: 644px) {
  .sideBar {
    text-align: left;
    font-family: "tiliweb regular";


    .main {
      .contentDashbord {
        // height: 100vh;

        background-color: transparent;
        border-radius: 4px;
        color: black;
      }
    }

    .bottom {
      position: absolute;
      bottom: 1rem;
      display: flex;
      justify-content: space-between;
    }
  }
}
